import React, { useEffect, Component } from "react";
import { graphql, useStaticQuery } from 'gatsby'

import "./styles.scss"
import Privacy from "../Components/Policy/Policy"
import Footer from "../Components/Footer/Footer"


import Layout from "../Components/layout"
// import SEO from "../components/seo"

const PrivacyPolicy = (props) => {
  // console.log(props);
  return(
    <div>
      <Layout>
        <Privacy/>
        <Footer/>
      </Layout>
    </div>
  )
}

export default PrivacyPolicy