import React from 'react';
import { AnchorLink } from "gatsby-plugin-anchor-links";
import './styles.scss';

const app_name = "nowCaptain";

function Policy() {
  return (
    <div className="container-fluid terms">
      <div className="row-fluid">
        <div className="centering text-center justify-content-center align-self-center pt-5">
          <h2>Πολιτική cookie</h2>
          <div className="pt-4 pb-4">
            <p className="text-justify text-monospace p-1 text-center">Αυτή είναι η Πολιτική cookie για το {app_name}, προσβάσιμη από www.nowcaptain.com</p>

            <p className="text-justify text-monospace p-1 font-weight-bold"><strong>Τι είναι τα cookies;</strong></p>

            <p className="text-justify text-monospace p-1">Όπως είναι κοινή πρακτική σε σχεδόν όλους τους επαγγελματικούς ιστότοπους, αυτός ο ιστότοπος χρησιμοποιεί cookies, τα οποία είναι μικροσκοπικά αρχεία που λαμβάνονται στον υπολογιστή σας, για τη βελτίωση της εμπειρίας σας. Αυτή η σελίδα περιγράφει ποιες πληροφορίες συλλέγουν, πώς τις χρησιμοποιούμε και γιατί μερικές φορές χρειαζόμαστε να αποθηκεύουμε αυτά τα cookies. Θα μοιραστούμε επίσης πώς μπορείτε να αποτρέψετε την αποθήκευση αυτών των cookie, ωστόσο αυτό μπορεί να υποβαθμίσει ή να «σπάσει» ορισμένα στοιχεία της λειτουργικότητας των ιστότοπων.</p>

            <p className="text-justify text-monospace p-1 font-weight-bold"><strong>Πώς χρησιμοποιούμε τα cookies</strong></p>

            <p className="text-justify text-monospace p-1">Χρησιμοποιούμε cookies για διάφορους λόγους που αναφέρονται παρακάτω. Δυστυχώς, στις περισσότερες περιπτώσεις, δεν υπάρχουν τυπικές επιλογές για την απενεργοποίηση των cookie χωρίς να απενεργοποιήσετε πλήρως τη λειτουργικότητα και τις δυνατότητες που προσθέτουν σε αυτόν τον ιστότοπο. Συνιστάται να αφήσετε όλα τα cookies εάν δεν είστε σίγουροι αν τα χρειάζεστε ή όχι σε περίπτωση που χρησιμοποιούνται για την παροχή μιας υπηρεσίας που χρησιμοποιείτε.</p>

            <p className="text-justify text-monospace p-1 font-weight-bold"><strong>Απενεργοποίηση Cookies</strong></p>

            <p className="text-justify text-monospace p-1">Μπορείτε να αποτρέψετε τη ρύθμιση των cookies προσαρμόζοντας τις ρυθμίσεις στο πρόγραμμα περιήγησής σας (ανατρέξτε στη Βοήθεια του προγράμματος περιήγησής σας για το πώς να το κάνετε αυτό). Λάβετε υπόψη ότι η απενεργοποίηση των cookies θα επηρεάσει τη λειτουργικότητα αυτού και πολλών άλλων ιστότοπων που επισκέπτεστε. Η απενεργοποίηση των cookies συνήθως έχει ως αποτέλεσμα την απενεργοποίηση ορισμένων λειτουργιών και λειτουργιών αυτού του ιστότοπου. Επομένως, συνιστάται να μην απενεργοποιείτε τα cookie.</p>

            <p className="text-justify text-monospace p-1 font-weight-bold"><strong>Τα Cookies που θέτουμε</strong></p>

            <ul className="list-group">



              <li className="list-group-item">
                <p className="list-group-item">Cookies σχετικά με ενημερωτικά δελτία μέσω email</p>
                <p className="list-group-item">Αυτός ο ιστότοπος προσφέρει υπηρεσίες συνδρομής σε ενημερωτικό δελτίο ή email και τα cookies μπορεί να χρησιμοποιηθούν για να θυμόμαστε εάν είστε ήδη εγγεγραμμένοι και εάν θα εμφανίζονται συγκεκριμένες ειδοποιήσεις που ενδέχεται να ισχύουν μόνο για εγγεγραμμένους / μη εγγεγραμμένους χρήστες.</p>
              </li>



              <li className="list-group-item">
                <p className="list-group-item">Cookies που σχετίζονται με φόρμες</p>
                <p className="list-group-item">Όταν υποβάλλετε δεδομένα μέσω μιας φόρμας όπως αυτές που βρίσκονται σε σελίδες επαφών ή σε φόρμες σχολίων, τα cookies μπορεί να ρυθμιστούν ώστε να θυμούνται τα στοιχεία του χρήστη σας για μελλοντική αλληλογραφία.</p>
              </li>


            </ul>

            <p className="text-justify text-monospace p-1 font-weight-bold"><strong>Cookies τρίτων</strong></p>

            <p className="text-justify text-monospace p-1">Σε ορισμένες ειδικές περιπτώσεις χρησιμοποιούμε επίσης cookie που παρέχονται από αξιόπιστα τρίτα μέρη. Η ακόλουθη ενότητα περιγράφει ποια cookie τρίτων ενδέχεται να συναντήσετε μέσω αυτού του ιστότοπου.</p>

            <ul className="list-group">

              <li className="list-group-item">
                <p className="text-justify text-monospace p-1">Αυτός ο ιστότοπος χρησιμοποιεί το Google Analytics που είναι μια από τις πιο διαδεδομένες και αξιόπιστες λύσεις ανάλυσης στον ιστό για να μας βοηθήσει να κατανοήσουμε πώς χρησιμοποιείτε τον ιστότοπο και τρόπους με τους οποίους μπορούμε να βελτιώσουμε την εμπειρία σας. Αυτά τα cookie μπορεί να παρακολουθούν πράγματα, όπως πόσο καιρό ξοδεύετε στον ιστότοπο και στις σελίδες που επισκέπτεστε, ώστε να συνεχίσουμε να παράγουμε ελκυστικό περιεχόμενο.</p>
                <p className="text-justify text-monospace p-1">Για περισσότερες πληροφορίες σχετικά με τα cookie του Google Analytics, ανατρέξτε στην επίσημη σελίδα του Google Analytics.</p>
              </li>

              <li className="list-group-item">
                <p className="text-justify text-monospace p-1">Τα αναλυτικά στοιχεία τρίτων χρησιμοποιούνται για την παρακολούθηση και τη μέτρηση της χρήσης αυτού του ιστότοπου, ώστε να μπορούμε να συνεχίσουμε να παράγουμε ελκυστικό περιεχόμενο. Αυτά τα cookie μπορεί να παρακολουθούν πράγματα, όπως πόσο καιρό ξοδεύετε στον ιστότοπο ή σε σελίδες που επισκέπτεστε, κάτι που μας βοηθά να κατανοήσουμε πώς μπορούμε να βελτιώσουμε τον ιστότοπο για εσάς.</p>
              </li>

              <li className="list-group-item">
                <p className="text-justify text-monospace p-1">Κατά καιρούς δοκιμάζουμε νέες δυνατότητες και κάνουμε λεπτές αλλαγές στον τρόπο παράδοσης του ιστότοπου. Όταν εξακολουθούμε να δοκιμάζουμε νέες δυνατότητες, αυτά τα cookie μπορεί να χρησιμοποιηθούν για να διασφαλιστεί ότι θα λάβετε μια συνεπή εμπειρία ενώ βρίσκεστε στον ιστότοπο, ενώ διασφαλίζετε ότι κατανοούμε ποιες βελτιστοποιήσεις εκτιμούν περισσότερο οι χρήστες μας.</p>
              </li>
            </ul>

            <p className="text-justify text-monospace p-1 font-weight-bold"><strong>Περισσότερες πληροφορίες</strong></p>

            <p className="text-justify text-monospace p-1">Ας ελπίσουμε ότι αυτό έχει ξεκαθαρίσει τα πράγματα για εσάς και όπως αναφέρθηκε προηγουμένως εάν υπάρχει κάτι που δεν είστε σίγουροι αν χρειάζεστε ή όχι, είναι συνήθως ασφαλέστερο να αφήσετε τα cookies ενεργοποιημένα σε περίπτωση που αλληλεπιδρά με μια από τις λειτουργίες που χρησιμοποιείτε στον ιστότοπό μας.</p>

            <p className="text-justify text-monospace p-1">Ωστόσο, εάν εξακολουθείτε να αναζητάτε περισσότερες πληροφορίες, μπορείτε να επικοινωνήσετε μαζί μας μέσω μιας από τις προτιμώμενες μεθόδους επικοινωνίας μας:</p>
            <AnchorLink className="btn btn-dark text-light link-nav" to="/#contact" >Επικοινωνήστε μαζί μας</AnchorLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Policy;